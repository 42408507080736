<!--
 * @Description: 好物推荐组件
 * @Author: 琢磨先生
 * @Date: 2022-06-15 15:20:48
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-06-16 02:55:03
-->

<template>
  <div class="grid">
    <div v-for="i in 4" :key="i" class="item"></div>
    <div class="tip">从推荐商品加载数据</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},

  methods: {},
};
</script>

<style  scoped>
.grid {
  background-color: #d3dce6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  column-gap: 20px;
  position: relative;
}

.grid .item {
  height: 180px;
  background-color: #99a9bf;
}
.tip {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>